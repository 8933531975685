import { type ILocalizedContent, LocalesEnum } from '@/types/locales';
import type { IGroup } from '@/types/groups';
import type { IBasket } from '@/types/baskets';
import type { TournamentSystemName } from '@/constants/tournaments.const';

export enum StageSlug {
  Group = 'final-tournament-main-tournament',
  LeagueOfChampionsGroup = 'league-of-champions-24-group-stage',
}

export interface ITournamentStage {
  id: number;
  slug: string;
  name: ILocalizedContent;
  created_at: Date | null;
  started_at: Date | null;
  finished_at: Date | null;
  updated_at: Date | null;
  api_id: string;
  tournament_id: number;
  system_name: string;
}

export interface ITournamentFromApi {
  id: number;
  name: {
    ru: string;
    en: string;
  }
  slug: string;
  groups?: IGroup[];
  sport: ISport;
  sport_id: number;
  prediction_types: IPrediction[];
  started_at: Date | null;
  finished_at: Date | null;
  created_at: string;
  updated_at: string;
  distribute_at: Date | null;
  stages: ITournamentStage[];
  system_name: string | TournamentSystemName;
}

export interface ITournamentDistributionResult {
  group: IGroup | null;
  team: IBasket | null;
}

export interface ITournament extends ITournamentFromApi {
  theme: TournamentTheme;
  distributionResult: ITournamentDistributionResult;
}

export interface ISport {
  id: number;
  name: {
    ru: string;
    en: string;
  }
  slug: string;
  created_at: string;
  updated_at: string;
  system_name: string;
}

export interface IPrediction {
  id: number
  name: {
    [LocalesEnum.RUS]: string;
    [LocalesEnum.ENG]: string;
  }
  system_name: PredictionsEnum;
}

export enum PredictionsEnum {
  ExactScore = 'match',
  Winner = 'group',
  TopGoalscorer = 'scorer',
  TopGoalkeeper = 'keeper',
  League = 'league',
  ProductiveTeam = 'most_productive_team',
  UfcWinner = 'ufc_winner',
}

export interface ITournamentCardInfo {
  id: number;
  slug: string;
  stages: ITournamentStage[];
  name: {
    ru: string;
    en: string;
  };
  dateStart: Date | null;
  dateFinish: Date | null;
  logos: {
    officialLogoSrc: string | null;
    themeLogoSrc: string | null;
  }
}

export enum TournamentTheme {
  PathOfSage = 'PATH_OF_SAGE',
  FootballElements = 'FOOTBALL_ELEMENTS',
  UFC = 'UFC',
}
