import { defineStore } from 'pinia';
import type { IError } from '@/types/api';
import { getError } from '@/helpers/errorHandler';
import api from '@/api';
import type {
  IUserMailingPayload,
  IUserMailingResponse,
  IUserRankingResponse,
  IUserStatistics,
  IUserStatisticsResponse,
  IUserRanking,
} from '@/types/user';
import type { LocalesEnum } from '@/types/locales';
import { useAuthStore } from '@/stores/auth';

interface IState {
  statistics: {
    data: IUserStatistics | null;
    loading: boolean;
  };
  ranking: {
    data: IUserRanking | null;
    loading: boolean;
  };
}

export const useUserStore = defineStore('userStore', {
  state: (): IState => ({
    statistics: {
      data: null,
      loading: false,
    },
    ranking: {
      data: null,
      loading: false,
    },
  }),

  actions: {
    async mailing(payload: IUserMailingPayload): Promise<IUserMailingResponse | IError> {
      try {
        const authStore = useAuthStore();
        const { data } = await api.user.mailing(payload);

        if (authStore.user) {
          authStore.user.has_mailing = !authStore.user.has_mailing;
        }

        return data;
      } catch (error) {
        return getError(error);
      }
    },

    async getStatistics(slug: string): Promise<IUserStatisticsResponse | IError> {
      try {
        this.statistics.loading = true;
        this.statistics.data = null;

        const { data } = await api.user.statistics(slug);

        if (data.data) {
          this.statistics.data = data.data;
        }

        return data;
      } catch (error) {
        return getError(error);
      } finally {
        this.statistics.loading = false;
      }
    },

    async getRanking(slug: string): Promise<IUserRankingResponse | IError> {
      try {
        this.ranking.loading = true;
        this.ranking.data = null;

        const { data } = await api.user.ranking(slug);

        if (data) {
          this.ranking.data = data;
        }

        return data;
      } catch (error) {
        return getError(error);
      } finally {
        this.ranking.loading = false;
      }
    },

    async changeLang(lang: LocalesEnum): Promise<{ data: true } | IError> {
      try {
        const { data } = await api.user.changeLang(lang);

        return data;
      } catch (error) {
        return getError(error);
      }
    },
  },
});
