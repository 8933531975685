import { createApp } from 'vue';
import { createPinia } from 'pinia';
import i18n from '@/locales';
// import * as Sentry from '@sentry/vue';

import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';

import router from './router';
import App from './App.vue';

import './assets/styles/main.scss';

dayjs.locale('ru');
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

const app = createApp(App);

app.use(i18n);
app.use(createPinia());
app.use(router);
app.use(PrimeVue, { unstyled: true });
app.use(ToastService);

// Sentry.init({
//   app,
//   dsn: import.meta.env.VITE_SENTRY_DSN,
//   integrations: [
//     Sentry.browserTracingIntegration({ router }),
//     Sentry.replayIntegration(),
//   ],
//
//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

app.mount('#app');
