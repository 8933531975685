<script setup lang="ts">
import { computed } from 'vue';
import { TournamentTheme } from '@/types/tournaments';

interface BaseSelectTab {
  label: string;
  slug: string;
  disabled?: boolean;
}

const props = defineProps<{
  tabs: BaseSelectTab[];
  currentTab: BaseSelectTab;
  theme?: string | TournamentTheme;
}>();

const emits = defineEmits(['update:currentTab', 'select']);

const currentOption = computed({
  get() {
    return props.currentTab;
  },
  set(newValue) {
    emits('update:currentTab', newValue);
  },
});

const selectTab = (payload: BaseSelectTab) => {
  currentOption.value = payload;
  emits('select', payload);
};
</script>

<template>
  <div
    class="base-tabs"
    :class="{
      'base-tabs--default': props.theme !== TournamentTheme.UFC,
      'base-tabs--ufc': props.theme === TournamentTheme.UFC,
    }"
  >
    <ul class="base-tabs__list">
      <li
        v-for="tab of props.tabs"
        :key="tab.slug"
      >
        <button
          class="base-tabs__item title-12-light"
          :class="{
            'base-tabs__item--default': props.theme !== TournamentTheme.UFC,
            'base-tabs__item--ufc': props.theme === TournamentTheme.UFC,
            'current': tab.slug === currentOption.slug,
          }"
          :disabled="tab.disabled"
          @click="selectTab(tab)"
        >
          {{tab.label}}
        </button>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.base-tabs {
  width: fit-content;
  padding: 8px;
  border-radius: 12px;
  background-color: $clear-1;
  -webkit-backdrop-filter: blur(250px);
  backdrop-filter: blur(250px);

  &--ufc {
    background-color: transparent;
    -webkit-backdrop-filter: initial;
    backdrop-filter: initial;
  }
}

.base-tabs__list {
  display: flex;
  align-items: center;
  gap: 4px;
}

.base-tabs__item {
  width: 100%;
  min-width: 124px;
  padding: 8px 20px;
  text-align: center;
  cursor: pointer;
  transition: all 300ms ease;

  &--default {
    border-radius: 4px;
    background-color: $clear-10;
    color: $clear-80;

    &:not([disabled]) {
      &:hover,
      &:focus {
        background-color: $clear-100;
        color: $lilac-150;
      }
    }

    &:disabled {
      background-color: $clear-5;
      color: $clear-10;
      cursor: default;
    }

    &.current {
      background-color: $clear-100;
      color: $lilac-150;
    }
  }

  &--ufc {
    border-radius: 2px;
    background-color: $clear-10;
    color: $clear-80;

    &:not([disabled]) {
      &:hover,
      &:focus {
        background-color: $ufc-dark-100;
        color: $ufc-primary-100;
      }
    }

    &:disabled {
      background-color: $clear-5;
      color: $clear-10;
      cursor: default;
    }

    &.current {
      background-color: $ufc-dark-200;
      color: $clear-100;
      border-bottom: 1px solid $ufc-primary-100;
    }
  }
}
</style>
