<script setup lang="ts">
import { computed, ref } from 'vue';
import AppIcon from '@/components/App/Icon/AppIcon.vue';
import { TournamentTheme } from '@/types/tournaments';
import type { BaseSelectOption } from '@/types/base.types';

const props = defineProps<{
  options: BaseSelectOption[];
  selectedOption: BaseSelectOption;
  showSelectedOptionsInOptions?: boolean;
  theme: 'main' | 'tournament' | TournamentTheme;
}>();

const emits = defineEmits(['update:selectedOption', 'select']);

const currentOption = computed({
  get() {
    return props.selectedOption;
  },
  set(newValue) {
    emits('update:selectedOption', newValue);
  },
});

const optionsList = computed(() => {
  if (props.showSelectedOptionsInOptions) {
    return props.options;
  }

  return props.options.filter((option) => option.slug !== currentOption.value.slug);
});

const isOpened = ref(false);

const toggleDropdown = () => {
  isOpened.value = !isOpened.value;
};

const selectOption = (payload: BaseSelectOption) => {
  currentOption.value = payload;
  emits('select', payload);
  isOpened.value = false;
};
</script>

<template>
  <div class="base-select">
    <button
      class="base-select__button title-16-light"
      :class="{
        'base-select__button--main': props.theme === 'main',
        'base-select__button--tournament': props.theme === 'tournament',
        'base-select__button--pos': props.theme === TournamentTheme.PathOfSage,
        'base-select__button--fe': props.theme === TournamentTheme.FootballElements,
        'base-select__button--ufc': props.theme === TournamentTheme.UFC,
      }"
      @click="toggleDropdown"
    >
      <AppIcon
        v-if="props.theme === TournamentTheme.FootballElements"
        name="ball"
        class="base-select__left-icon"
      />

      {{ currentOption.label }}

      <AppIcon
        name="arrow-down"
        class="base-select__toggle-icon"
        :class="{'base-select__toggle-icon--opened': isOpened}"
      />
    </button>

    <transition name="base-select">
      <ul
        v-if="isOpened"
        class="base-select__list"
        :class="{
          'base-select__list--main': props.theme === 'main',
          'base-select__list--tournament': props.theme === 'tournament',
          'base-select__list--pos': props.theme === TournamentTheme.PathOfSage,
          'base-select__list--fe': props.theme === TournamentTheme.FootballElements,
          'base-select__list--ufc': props.theme === TournamentTheme.UFC,
        }"
      >
        <li
          v-for="(option, index) in optionsList"
          :key="option.slug"
        >
          <button
            :tabindex="index + 1"
            class="base-select__item title-16-light"
            :class="{
              'base-select__item--main': props.theme === 'main',
              'base-select__item--tournament': props.theme === 'tournament',
              'base-select__item--pos': props.theme === TournamentTheme.PathOfSage,
              'base-select__item--fe': props.theme === TournamentTheme.FootballElements,
              'base-select__item--ufc': props.theme === TournamentTheme.UFC,
              'current': currentOption.slug === option.slug,
            }"
            @click="selectOption(option)"
          >
            {{ option.label }}
          </button>
        </li>
      </ul>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.base-select {
  position: relative;
}

.base-select__button {
  position: relative;
  width: 100%;
  min-height: 48px;
  padding: 8px 40px;
  background-color: $clear-10;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);

  &--main {
    background: $blue-5;
    text-align: left;
    padding-left: 12px;
    color: $clear-80;

    &::before {
      content: "";
      position: absolute;
      inset: 1px;
      border-radius: 8px;
      padding: 1px;
      background: $gradient-20;
      mask:
        linear-gradient($clear-100 0%, $clear-100 0%) content-box,
        linear-gradient($clear-100 0%, $clear-100 0%);
      mask-composite: exclude;
      pointer-events: none;
      transition: all 0.2s;
    }
  }

  &--pos {
    background-color: $lilac-150;
    text-align: left;
    padding-left: 20px;
  }

  &--fe {
    background-color: $blue-5;
    text-align: left;
    color: $green;
  }

  &--ufc {
    border-radius: 2px;
    background-color: $ufc-dark-20;
    border: 1px solid $ufc-primary-10;

    .base-select__toggle-icon {
      color: $ufc-primary-100;
    }
  }
}

.base-select__left-icon {
  position: absolute;
  left: 16px;
  top: 50%;
  width: 17px;
  height: 17px;
  transform: translateY(-50%);
}

.base-select__toggle-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  width: 32px;
  height: 32px;
  transform: translateY(-50%);
  color: $clear-70;

  &--opened {
    transform: translateY(-50%) rotate(180deg);
  }
}

.base-select__list {
  z-index: $z-level-2;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  background-color: $clear-10;
  border-radius: 8px;
  overflow: hidden;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);

  &--main {
    background-color: $main-surface-color;
    padding: 4px;
  }

  &--pos {
    background-color: $lilac-150;
  }

  &--ufc {
    border-radius: 2px;
    background-color: $ufc-dark-20;
    border: 1px solid $ufc-primary-10;
  }
}

.base-select__item {
  width: 100%;
  padding: 16px 40px;
  text-align: center;
  cursor: pointer;
  color: $clear-80;
  transition: all 250ms ease-in-out;

  &:hover,
  &:focus {
    background-color: $clear-100;
    color: $lilac-150;
  }

  &--main {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    border-radius: 4px;
    text-align: left;

    &:hover,
    &:focus {
      background: $blue-10;
      color: $clear-80;
    }
  }

  &--main.current {
    background: $gradient-20;
    color: $green;
  }

  &--pos {
    text-align: left;
    padding-left: 20px;
    color: $clear-60;

    &:hover,
    &:focus {
      color: $clear-100;
      background-color: transparent;
    }
  }

  &--fe {
    text-align: left;
    color: $clear-60;
  }

  &--ufc {
    text-align: center;
    color: $clear-60;

    &:hover,
    &:focus {
      color: $clear-100;
      background-color: transparent;
    }
  }
}

.base-select-enter-active,
.base-select-leave-active {
  transition: opacity 300ms ease;
}

.base-select-enter,
.base-select-leave-to {
  opacity: 0;
}
</style>
