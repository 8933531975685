<script setup lang="ts">
import { ref } from 'vue';
import UserIcon from '@/assets/icons/user.svg';
import { TournamentTheme } from '@/types/tournaments';

const props = defineProps<{
  src: null | string;
  alt?: string;
  theme: 'main' | 'tournament' | TournamentTheme;
}>();

const isLoadError = ref<boolean>(false);

const handleImageError = () => {
  isLoadError.value = true;
};
</script>

<template>
  <div
    class="avatar"
    :class="{
      'avatar--main': props.theme === 'main',
      'avatar--pos': props.theme === TournamentTheme.PathOfSage || props.theme === 'tournament',
      'avatar--fe': props.theme === TournamentTheme.FootballElements,
      'avatar--ufc': props.theme === TournamentTheme.UFC,
    }"
  >
    <div class="avatar__inner">
      <img
        v-if="props.src && !isLoadError"
        class="avatar__image"
        :src="props.src"
        :alt="props.alt ?? ''"
        @error="handleImageError"
      >

      <UserIcon
        v-else
        class="avatar__icon"
      />
    </div>
  </div>
</template>

<style lang="scss">
.avatar {
  padding: 1px;
  position: relative;
  border-radius: 50%;
  background: transparent;
  overflow: hidden;
  width: 34px;
  height: 34px;
  transition: all 200ms ease-in-out;

  &--main {
    &:active {
      background: $gradient;
    }
  }

  &--pos {
    &:active {
      background: $yellow-120;
    }

    @include lg {
      &:hover {
        background: $yellow-120;
      }
    }
  }

  &--fe {
    &:active {
      background: $yellow-120-fe;
    }

    @include lg {
      &:hover {
        background: $yellow-120-fe;
      }
    }
  }

  &--ufc {
    &:active {
      background: $ufc-primary-100;
    }

    @include lg {
      &:hover {
        background: $ufc-primary-100;
      }
    }
  }
}

.avatar__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  min-width: 100%;
  height: 100%;
  border-radius: 50%;
}

.avatar__image {
  object-fit: cover;
}

.avatar__icon {
  width: 100%;
  height: 100%;
}
</style>
