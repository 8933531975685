import { type ITournamentFromApi, PredictionsEnum } from '@/types/tournaments';

export const mockTournamentFromApi: ITournamentFromApi = {
  id: 2,
  name: {
    en: 'UFC',
    ru: 'UFC',
  },
  system_name: 'ufc',
  slug: 'league-of-champions-24',
  sport: {
    id: 1,
    name: {
      ru: 'Ufc',
      en: 'Ufc',
    },
    slug: 'ufc',
    created_at: '2024-06-12T17:19:20.000000Z',
    updated_at: '2024-11-13T23:04:26.000000Z',
    system_name: 'football',
  },
  sport_id: 1,
  distribute_at: null,
  prediction_types: [
    {
      id: 8,
      name: {
        ru: 'Прогнозы на бой',
        en: 'Predictions for the fight',
      },
      system_name: PredictionsEnum.UfcWinner,
    },
  ],
  stages: [
    {
      id: 3,
      name: {
        en: 'UEFA - League',
        ru: 'Общий этап',
      },
      api_id: '2oN82Fw5',
      slug: 'league-of-champions-24-group-stage',
      tournament_id: 2,
      started_at: new Date('2024-09-17T16:45:00.000000Z'),
      finished_at: new Date('2025-01-29T20:00:00.000000Z'),
      created_at: new Date('2024-09-13T14:00:53.000000Z'),
      updated_at: new Date('2024-09-13T14:01:04.000000Z'),
      system_name: 'uefa_group_stage',
    },
  ],
  started_at: new Date('2024-09-17T16:45:00.000000Z'),
  finished_at: new Date('2025-01-29T20:00:00.000000Z'),
  created_at: '2024-09-13T14:00:53.000000Z',
  updated_at: '2024-09-13T14:53:56.000000Z',
};
