<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { localStorageHelper } from '@/helpers/localStorage';
import { useDate } from '@/composables/useDate';
import { useUserStore } from '@/stores/user';
import type { LocalesEnum } from '@/types/locales';
import { useAuthStore } from '@/stores/auth';
import { TournamentTheme } from '@/types/tournaments';

const props = defineProps<{
  theme: 'main' | TournamentTheme;
}>();

const { locale, availableLocales } = useI18n();
const { setLocale } = useDate();
const storage = localStorageHelper();
const authStore = useAuthStore();
const userStore = useUserStore();

const toggleTheme = () => {
  const anotherLocale = availableLocales.find((availableLocale) => availableLocale !== locale.value);

  if (anotherLocale) {
    locale.value = anotherLocale as LocalesEnum;
    setLocale(anotherLocale);
    storage.setItemInLocalStorage(import.meta.env.VITE_STORAGE_KEY_LOCALE, anotherLocale);

    if (authStore.user) {
      userStore.changeLang(anotherLocale as LocalesEnum);
    }
  }
};
</script>

<template>
  <button
    class="theme-switcher title-14"
    :class="{
      'theme-switcher--main': props.theme === 'main',
      'theme-switcher--pos': props.theme === TournamentTheme.PathOfSage,
      'theme-switcher--fe': props.theme === TournamentTheme.FootballElements,
      'theme-switcher--ufc': props.theme === TournamentTheme.UFC,
    }"
    @click="toggleTheme"
  >
    {{ locale }}
  </button>
</template>

<style lang="scss" scoped>
.theme-switcher {
  color: $clear-100;
  transition: all 300ms ease;
  text-transform: uppercase;

  &--main {
    &:hover,
    &:focus {
      background: $gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &:active {
      color: $success-100;
      background: inherit;
      -webkit-background-clip: initial;
      -webkit-text-fill-color: initial;
    }
  }

  &--pos {
    &:hover,
    &:focus {
      color: $yellow-100;
    }

    &:active {
      color: $yellow-120;
    }

    &--active {
      color: $yellow-120;
    }
  }

  &--fe {
    &:hover,
    &:focus {
      color: $yellow-100-fe;
    }

    &:active {
      color: $yellow-120-fe;
    }

    &--active {
      color: $yellow-100-fe;
    }
  }

  &--ufc {
    &:hover,
    &:focus {
      color: $ufc-primary-100;
    }

    &:active {
      color: $ufc-primary-80;
    }

    &--active {
      color: $ufc-primary-100;
    }
  }
}
</style>
